import React from 'react'
import TextRenderer from './renderer'
import { wrapper } from './index.module.scss'

const Text = ({ text='' }) => {
  return (
    <div className={wrapper}>
      <TextRenderer text={text}/>
    </div>
  )
}

export default Text
