import { caption, wrapper } from './index.module.scss'

import { GatsbyImage } from 'gatsby-plugin-image'
import React from "react"

const Photos = ({ photos=[] }) => {
  return (
    <div className={wrapper}>
      {photos.map((photo, index) => {
        return (
          <React.Fragment key={index}>
            <GatsbyImage image={photo.photo.gatsbyImageData} alt={photo.alt}/>
            {photo.alt && <div className={caption}>{photo.alt}</div>}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default Photos
