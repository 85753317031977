import { graphql, useStaticQuery } from "gatsby"

import Fields from '../components/fields'
import Layout from '../layouts'
import PageHeader from '../components/page/header'
import Photos from '../components/photos'
import React from 'react'
import Seo from '../components/seo'
import Text from '../components/text'

const Therapy = () => {
  const { pageHeaderImage, fields, photoOne, photoTwo } = useStaticQuery(
    graphql`
      {
        pageHeaderImage: imageSharp(resize: {originalName: {eq: "neuro-athletik.jpg"}}) {
          gatsbyImageData(
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1000
          )
        }

        photoOne: imageSharp(resize: {originalName: {eq: "neuro-athletik-riechen.jpg"}}) {
          gatsbyImageData(
            height: 710
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1110
          )
        }

        photoTwo: imageSharp(resize: {originalName: {eq: "neuro-athletik-schocken.jpg"}}) {
          gatsbyImageData(
            height: 710
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1110
          )
        }

        fields: allNeuroAthletikJson {
          edges {
            node {
              name
              copy
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo
        title='Neuro-Athletik'
        currentUrl='/neuro-athletik'
      />
      <PageHeader
        headline='Neuro-Athletik'
        copy='Alle Prozesse, die im Gehirn stattfinden, also auch Bewegung und Schmerzwahrnehmung, können durch ausgewählte Übungen verbessert werden.'
        image={pageHeaderImage}
      />
      <Text text='Eine optimale körperliche Leistung sowie Beschwerdefreiheit ist nur dann möglich, wenn das Gehirn hochwertige Informationen von Augen, Gleichgewichtssystem und Körper erhält. Bekommt das zentrale Nervensystem mangelhafte oder falsche Informationen kann das Verspannungen, Dysbalancen und Schmerzen auslösen bzw. "fahren wir die ganze Zeit mit angezogener Handbremse". Mit individuell ausgewählten Übungen können schnell und effektiv die vielfältigsten Probleme gelöst werden.'/>
      <Fields fields={fields?.edges.map((node) => node.node )}/>
      <Text text={`
Neurotraining bietet ein enormes Potential für jeden Menschen, vom Kleinkind bis zum Senioren, vom vermeintlich Gesunden bis zum chronisch Kranken. Es kann positive Effekte haben bei Schmerzen, Ermüdung und Energielosigkeit, Gelenkblockaden und Beweglichkeitseinschränkungen, visuellen Problemen wie müden Augen und eingeschränkter Sicht, Leistungsdefiziten oder -plateaus im Sport sowie Schwindel und Depression haben.
      `}/>
      <Photos photos={[{ photo: photoOne, alt: "Riechen stimuliert das Nervensystem" }]}/>
      <Text text={`
Kopf und Körper sind eine Einheit. Unser Gehirn reguliert und kontrolliert alles im Körper. Es nimmt externe und interne Informationen als Input über unsere Sinnesorgane, z.B. die Augen oder Faszien auf, verarbeitet und interpretiert sie mit unserem vorhandenen Wissen und erzeugt daraus ein Endergebnis, den Output. Im Alltag ist jener maßgebliche Output die Bewegung. Wie wir uns bewegen, wird von den Prozessen in Gehirn und Nervensystem bestimmt. Wie viel Kraft, Beweglichkeit und ob du Schmerzen hast sind Entscheidungen vom Gehirn.
      `}/>
      <Photos photos={[{ photo: photoTwo, alt: "Vibration verbessert die Informationsübertragung ans Gehirn" }]}/>
      <Text text={`
Für jede Bewegung, die wir ausführen, benötigt das Gehirn sensorische Informationen aus den drei bewegungssteuernden Systemen Augen (visuelles System), Gleichgewicht (vestibuläres System) und Eigenwahrnehmung im Raum (propriozeptives System). Je klarer und hochwertiger die Signale aus diesen Systemen sind, desto besser ist die körperliche Leistungsfähigkeit. Kommt es zu Störungen in der Kommunikation oder sind die Informationen mangelhaft, wirkt sich das negativ auf die Haltung und Bewegung aus und somit u.a. auf deine sportliche und berufliche Leistungsfähigkeit.

Mit individuell ausgewählten Übungen können wir eine nachhaltige positive Wirkung erzielen. Die Übungen können selbstständig und unkompliziert in Alltag, Beruf sowie Sport integriert werden.
      `}/>
    </Layout>
  )
}

export default Therapy
