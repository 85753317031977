import { container, copy, field, fields, title, wrapper } from './index.module.scss'

import React from "react"
import TextRenderer from '../text/renderer'

const Fields = ({ fields: items }) => {
  return (
    <div className={wrapper}>
      <div className={container}>
        <div className={fields}>
          {items.map((item, index) => (
            <div key={index} className={field}>
              <h3 className={title}>{item.name}</h3>
              <div className={copy}>
                <TextRenderer text={item.copy}/>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Fields
